import { initializeApp } from 'firebase/app';
import {getAuth} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
	apiKey: 'AIzaSyDLDvqxxhVZH9pHLDtQlXRVpcbE4kM8_xY',
	authDomain: 'foodlogin-f1501.firebaseapp.com',
	projectId: 'foodlogin-f1501',
	storageBucket: 'foodlogin-f1501.appspot.com',
	messagingSenderId: '942997823019',
	appId: '1:942997823019:web:5043dc0dc500b74960d46e',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);
export default app;
