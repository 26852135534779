import React,{useState} from 'react'
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../styles/payment.css'

const Payment = () => {
	const shippingInfo = [];
	const cartTotalAmount = useSelector((state) => state.cart.totalAmount);
	const shippingCost = 30;

	const totalAmount = cartTotalAmount + Number(shippingCost);

	const [cardNumber, setCardNumber] = useState('');
	const [mmyy, setMMyy] = useState('');
	const [cvvcode, setCvvcode] = useState('');
	const [nameofthecard, setNameofthecard] = useState('');



	const submitHandler = (e) => {
		e.preventDefault();
		const userShippingAddress = {
			cardNumber: cardNumber,
			mmyy: mmyy,
			cvvcode: cvvcode,
			nameofthecard: nameofthecard,
		};

		shippingInfo.push(userShippingAddress);
		console.log(shippingInfo);
	};

	return (
		<>
			<section className="payment">
				<Container>
					<Row>
						<Col lg="6" md="6">
							{/* <form className="pay__form">
								<div className="form__group">
									<input type="text" placeholder="Holder name" required />
								</div>

								<div className="form__group__pay">
									<input type="email" placeholder="card number" required />
								</div>
								<div className="form__group__pay">
									<input type="number" placeholder="Expiry date" required />
								</div>
								<div className="form__group__pay">
									<input type="number" placeholder="Security code" required />
								</div>

								<button type="submit" className="addTOCart__btn">
									<Link to="/payment">Payment</Link>
								</button>
							</form> */}
							<div className="col-12 mt-4">
								<div className="card p-3">
									<p className="mb-0 fw-bold h4">Payment Methods</p>
								</div>
							</div>
							<div className="col-12">
								<div className="card p-3">
									<div className="card-body border p-0">
										<div
											className="collapse show p-3 pt-0"
											id="collapseExample"
										>
											<div className="row1">
												<div className="col-lg-12">
													<form
														action=""
														className="form__pay"
														onSubmit={submitHandler}
													>
														<div className="row">
															<div className="col-12">
																<div className="form__div mt__10">
																	<input
																		type="text"
																		className="form-control"
																		placeholder=" Card Number"
																		required="required"
																		onChange={(e) =>
																			setCardNumber(e.target.value)
																		}
																	/>
																</div>
															</div>

															<div className="col-6">
																<div className="form__div mt__10">
																	<input
																		type="text"
																		className="form-control"
																		placeholder="MM / yy "
																		required
																		onChange={(e) => setMMyy(e.target.value)}
																	/>
																</div>
															</div>

															<div className="col-6">
																<div className="form__div mt__10">
																	<input
																		type="password"
																		className="form-control"
																		placeholder="cvv code "
																		required
																		onChange={(e) => setCvvcode(e.target.value)}
																	/>
																</div>
															</div>
															<div className="col-12">
																<div className="form__div mt__10">
																	<input
																		type="text"
																		className="form-control"
																		placeholder="Name on the card "
																		required
																		onChange={(e) =>
																			setNameofthecard(e.target.value)
																		}
																	/>
																</div>
															</div>
															<div className="col-12">
																<Link to="/popup" className="popup__link">
																	<div className="btn btn-primary w-100">
																		Sumbit
																	</div>
																</Link>
															</div>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Col>

						<Col lg="6" md="6">
							<div className="checkout__bill" id="total__price">
								<h6
									className="d-flex align-items-center justify-content-between mb-3"
									id="border-b"
								>
									Subtotal: <span>₹{cartTotalAmount}</span>
								</h6>
								<h6
									className="d-flex align-items-center justify-content-between mb-3"
									id="border-b"
								>
									Shipping: <span>₹{shippingCost}</span>
								</h6>
								<div className="checkout__total">
									<h5 className="d-flex align-items-center justify-content-between">
										Total: <span>₹{totalAmount}</span>
									</h5>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
};

export default Payment