import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../pages/Home";
import AllFoods from "../pages/AllFoods";
import FoodDetails from "../pages/FoodDetails";
import Cart from "../pages/Cart";
import Checkout from "../pages/Checkout";
import Contact from "../pages/Contact";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Payment from "../pages/Payment";
import Profile from "../pages/Profile";
import Popup from "../components/Popup/Popup";
import TermsAndConditions from "../pages/TermsAndConditions";
import CookiePolicy from "../pages/CookiePolicy";
 
const Routers = () => {
  return (
		<Routes>
			<Route path="/" element={<Navigate to="/home" />} />
			<Route path="/home" element={<Home />} />
			<Route path="/foods" element={<AllFoods />} />
			<Route path="/foods/:id" element={<FoodDetails />} />
			<Route path="/cart" element={<Cart />} />
			<Route path="/checkout" element={<Checkout />} />
			<Route path="/login" element={<Login />} />
			<Route path="/register" element={<Register />} />
			<Route path="/contact" element={<Contact />} />
			<Route path="/payment" element={<Payment />} />
			<Route path="/profile" element={<Profile />} />
			<Route path="/popup" element={<Popup />} />
			<Route path="/TermsAndConditions" element={<TermsAndConditions />} />
			<Route path="/CookiePolicy" element={<CookiePolicy />} />
		</Routes>
	);
};

export default Routers;
