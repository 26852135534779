import React, { useState } from 'react';
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/common-section/CommonSection";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import '../styles/login.css'

// firebase
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../components/Firebase/Firebase';

const Login = () => {
  // const loginNameRef = useRef();
  // const loginPasswordRef = useRef();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

  // const submitHandler = (e) => {
  //   e.preventDefault();
  // };  
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await signInWithEmailAndPassword(auth, email, password);
			console.log('User logged in Successfully');
			window.location.href = '/checkout';
			// toast.success('User logged in Successfully', {
			// 	position: 'top-center',
			// });
		} catch (error) {
			console.log(error.message);

			// toast.error(error.message, {
			// 	position: 'bottom-center',
			// });
		}
	};

  return (
		<Helmet title="Login">
			<CommonSection title="Login" />
			<section>
				<Container>
					<Row>
						<Col lg="6" md="6" sm="12" className="m-auto text-center">
							<form className="form mb-5" onSubmit={handleSubmit}>
								<div className="form__group">
									{/* <input
										type="email"
										placeholder="Email"
										required
										ref={loginNameRef}
									/> */}
									<input
										type="email"
										placeholder="Enter email"
										required
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
								<div className="form__group">
									{/* <input
										type="password"
										placeholder="Password"
										required
										ref={loginPasswordRef}
									/> */}
									<input
										type="password"
										placeholder="Enter password"
										required
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
								<div className="form__group1">
									<input type="checkbox" required />
									<p className="continuing">
										By continuing, i agree to the terms of use & privacy policy
									</p>
								</div>
								<button type="submit" className="addTOCart__btn">
									Login
								</button>
							</form>
							<Link to="/register">
								Don't have an account? Create an account
							</Link>
						</Col>
					</Row>
				</Container>
			</section>
		</Helmet>
	);
};

export default Login;


