import { createUserWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import { auth} from '../components/Firebase/Firebase';
import { setDoc, doc } from 'firebase/firestore';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/UI/common-section/CommonSection';
//import { toast } from 'react-toastify/dist/components';

const Register = () => {

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');

	const handleRegister = async (e) => {
		e.preventDefault();
		try {
			await createUserWithEmailAndPassword(auth, email, password);
			const user = auth.currentUser;
			console.log(user);
			if (user) {
				await setDoc(doc( 'Users', user.uid), {
					email: user.email,
					firstName: fname,
					lastName: lname,
					photo: '',
				});
			}
			console.log('User Registered Successfully!!');
			// toast.success('User Registered Successfully!!', {
			// 	position: 'top-center',
			// });
		} catch (error) {
			console.log(error.message);
			// toast.error(error.message, {
			// 	position: 'bottom-center',
			// });
		}
	};

	return (
		<Helmet title="Signup">
			<CommonSection title="Signup" />
			<section>
				<Container>
					<Row>
						<Col lg="6" md="6" sm="12" className="m-auto text-center">
							<form className="form mb-5" onSubmit={handleRegister}>
								<div className="form__group">
									<input
										type="text"
										className="form-control"
										placeholder="First name"
										required
										onChange={(e) => setFname(e.target.value)}
									/>
								</div>
								<div className="form__group">
									<input
										type="text"
										className="form-control"
										placeholder="Last name"
										required
										onChange={(e) => setLname(e.target.value)}
									/>
								</div>
								<div className="form__group">
									<input
										type="email"
										className="form-control"
										placeholder="Enter email"
										required
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>

								<div className="form__group">
									<input
										type="password"
										className="form-control"
										placeholder="Enter password"
										required
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
								<button type="submit" className="addTOCart__btn">
									Sign Up
								</button>
							</form>
							<Link to="/login">Already have an account? Login</Link>
						</Col>
					</Row>
				</Container>
			</section>
		</Helmet>
	);
};

export default Register;
