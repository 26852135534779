import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import '../styles/terms.css'

const TermsAndConditions = () => {
	return (
		<>
			<div className="terms">
				<Container>
					<Row>
						<Col lg="12" md="12" sm="12">
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit.
								Eligendi iure perspiciatis at qui corporis accusamus distinctio!
								Laudantium atque architecto facere, sapiente sint magnam hic.
								Doloremque consequuntur quae vero quo accusantium beatae,
								provident nam fugit. Cum neque, doloribus dolore architecto nam
								maxime placeat dolor repellendus vel accusantium nobis natus
								omnis alias? Lorem ipsum dolor sit amet consectetur adipisicing
								elit. Eligendi iure perspiciatis at qui corporis accusamus
								distinctio! Laudantium atque architecto facere, sapiente sint
								magnam hic. Doloremque consequuntur quae vero quo accusantium
								beatae, provident nam fugit. Cum neque, doloribus dolore
								architecto nam maxime placeat dolor repellendus vel accusantium
								nobis natus omnis alias?
							</p>
							<div className="termsofuse">
								<h5>1. Terms of Use</h5>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit.
									Eligendi iure perspiciatis at qui corporis accusamus
									distinctio! Laudantium atque architecto facere, sapiente sint
									magnam hic. Doloremque consequuntur quae vero quo accusantium
									beatae, provident nam fugit. Cum neque, doloribus dolore
									architecto nam maxime placeat dolor repellendus vel
									accusantium nobis natus omnis alias? Lorem ipsum dolor sit
									amet consectetur adipisicing elit. Eligendi iure perspiciatis
									at qui corporis accusamus distinctio! Laudantium atque
									architecto facere, sapiente sint magnam hic. Doloremque
									consequuntur quae vero quo accusantium beatae, provident nam
									fugit. Cum neque, doloribus dolore architecto nam maxime
									placeat dolor repellendus vel accusantium nobis natus omnis
									alias?
								</p>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit.
									Eligendi iure perspiciatis at qui corporis accusamus
									distinctio! Laudantium atque architecto facere, sapiente sint
									magnam hic. Doloremque consequuntur quae vero quo accusantium
									beatae, provident nam fugit. Cum neque, doloribus dolore
									architecto nam maxime placeat dolor repellendus vel
									accusantium nobis natus omnis alias? Lorem ipsum dolor sit
									amet consectetur adipisicing elit. Eligendi iure perspiciatis
									at qui corporis accusamus distinctio! Laudantium atque
									architecto facere, sapiente sint magnam hic. Doloremque
									consequuntur quae vero quo accusantium beatae, provident nam
									fugit. Cum neque, doloribus dolore architecto nam maxime
									placeat dolor repellendus vel accusantium nobis natus omnis
									alias?
								</p>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit.
									Eligendi iure perspiciatis at qui corporis accusamus
									distinctio! Laudantium atque architecto facere, sapiente sint
									magnam hic. Doloremque consequuntur quae vero quo accusantium
									beatae, provident nam fugit. Cum neque, doloribus dolore
									architecto nam maxime placeat dolor repellendus vel
									accusantium nobis natus omnis alias? Lorem ipsum dolor sit
									amet consectetur adipisicing elit. Eligendi iure perspiciatis
									at qui corporis accusamus distinctio! Laudantium atque
									architecto facere, sapiente sint magnam hic. Doloremque
									consequuntur quae vero quo accusantium beatae, provident nam
									fugit. Cum neque, doloribus dolore architecto nam maxime
									placeat dolor repellendus vel accusantium nobis natus omnis
									alias?
								</p>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit.
									Eligendi iure perspiciatis at qui corporis accusamus
									distinctio! Laudantium atque architecto facere, sapiente sint
									magnam hic. Doloremque consequuntur quae vero quo accusantium
									beatae, provident nam fugit. Cum neque, doloribus dolore
									architecto nam maxime placeat dolor repellendus vel
									accusantium nobis natus omnis alias? Lorem ipsum dolor sit
									amet consectetur adipisicing elit. Eligendi iure perspiciatis
									at qui corporis accusamus distinctio! Laudantium atque
									architecto facere, sapiente sint magnam hic. Doloremque
									consequuntur quae vero quo accusantium beatae, provident nam
									fugit. Cum neque, doloribus dolore architecto nam maxime
									placeat dolor repellendus vel accusantium nobis natus omnis
									alias?
								</p>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit.
									Eligendi iure perspiciatis at qui corporis accusamus
									distinctio! Laudantium atque architecto facere, sapiente sint
									magnam hic. Doloremque consequuntur quae vero quo accusantium
									beatae, provident nam fugit. Cum neque, doloribus dolore
									architecto nam maxime placeat dolor repellendus vel
									accusantium nobis natus omnis alias? Lorem ipsum dolor sit
									amet consectetur adipisicing elit. Eligendi iure perspiciatis
									at qui corporis accusamus distinctio! Laudantium atque
									architecto facere, sapiente sint magnam hic. Doloremque
									consequuntur quae vero quo accusantium beatae, provident nam
									fugit. Cum neque, doloribus dolore architecto nam maxime
									placeat dolor repellendus vel accusantium nobis natus omnis
									alias?
								</p>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit.
									Eligendi iure perspiciatis at qui corporis accusamus
									distinctio! Laudantium atque architecto facere, sapiente sint
									magnam hic. Doloremque consequuntur quae vero quo accusantium
									beatae, provident nam fugit. Cum neque, doloribus dolore
									architecto nam maxime placeat dolor repellendus vel
									accusantium nobis natus omnis alias? Lorem ipsum dolor sit
									amet consectetur adipisicing elit. Eligendi iure perspiciatis
									at qui corporis accusamus distinctio! Laudantium atque
									architecto facere, sapiente sint magnam hic. Doloremque
									consequuntur quae vero quo accusantium beatae, provident nam
									fugit. Cum neque, doloribus dolore architecto nam maxime
									placeat dolor repellendus vel accusantium nobis natus omnis
									alias?
								</p>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit.
									Eligendi iure perspiciatis at qui corporis accusamus
									distinctio! Laudantium atque architecto facere, sapiente sint
									magnam hic. Doloremque consequuntur quae vero quo accusantium
									beatae, provident nam fugit. Cum neque, doloribus dolore
									architecto nam maxime placeat dolor repellendus vel
									accusantium nobis natus omnis alias? Lorem ipsum dolor sit
									amet consectetur adipisicing elit. Eligendi iure perspiciatis
									at qui corporis accusamus distinctio! Laudantium atque
									architecto facere, sapiente sint magnam hic. Doloremque
									consequuntur quae vero quo accusantium beatae, provident nam
									fugit. Cum neque, doloribus dolore architecto nam maxime
									placeat dolor repellendus vel accusantium nobis natus omnis
									alias?
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default TermsAndConditions