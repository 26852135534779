import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import logo from "../../assets/images/res-logo.png";
import plays from '../../assets/Appimg/play_store.png';
import apps from '../../assets/Appimg/app_store.png';

import "../../styles/footer.css";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
		<footer className="footer">
			<Container>
				<Row>
					<Col lg="3" md="4" sm="6">
						<div className=" footer__logo text-start">
							<img src={logo} alt="logo" />
							<h5>Tasty Treat</h5>
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit.
								Nesciunt pariatur accusamus
							</p>
						</div>
					</Col>

					<Col lg="3" md="4" sm="6">
						<h5 className="footer__title">Legal</h5>
						<ListGroup className="deliver__time-list">
							<ListGroupItem className=" delivery__time-item border-0 ps-0">
								{/* <span>Sunday - Thursday</span>
								<p>10:00am - 11:00pm</p> */}
								<Link to="/TermsAndConditions">Terms And Conditions</Link>
								<br />
								<Link to="/CookiePolicy">Cookie Policy</Link>
							</ListGroupItem>

							{/* <ListGroupItem className=" delivery__time-item border-0 ps-0">
								<span>Friday - Saturday</span>
								<p>Off day</p>
							</ListGroupItem> */}
						</ListGroup>
					</Col>

					<Col lg="3" md="4" sm="6">
						<h5 className="footer__title">Contact</h5>
						<ListGroup className="deliver__time-list">
							<ListGroupItem className=" delivery__time-item border-0 ps-0">
								<p>Location: Lorem ipsum dolor sit amet.</p>
							</ListGroupItem>
							<ListGroupItem className=" delivery__time-item border-0 ps-0">
								<span>Phone: 9985 **** **</span>
							</ListGroupItem>

							<ListGroupItem className=" delivery__time-item border-0 ps-0">
								<span>Email: example@gmail.com</span>
							</ListGroupItem>
						</ListGroup>
					</Col>

					<Col lg="3" md="4" sm="6">
						<h5 className="footer__title">Newsletter</h5>
						<p>Subscribe our newsletter</p>
						<div className="newsletter">
							<input type="email" placeholder="Enter your email" />
							<span>
								<i class="ri-send-plane-line"></i>
							</span>
						</div>
						<div className="appstorge">
							<img src={plays} alt="play store" />
							<img src={apps} alt="play store" />
						</div>
					</Col>
				</Row>
				<div className="topline"></div>
				<Row className="mt-5">
					<Col lg="6" md="6">
						<p className="copyright__text">
							Copyright - 2024, Food Online Oder.
						</p>
					</Col>
					<Col lg="6" md="6">
						<div className="social__links d-flex align-items-center gap-4 justify-content-end">
							<p className="m-0">Follow: </p>
							<span>
								{' '}
								<Link to="https://www.facebook.com/">
									<i class="ri-facebook-line"></i>
								</Link>{' '}
							</span>

							<span>
								<Link to="https://github.com/">
									<i class="ri-github-line"></i>
								</Link>
							</span>

							<span>
								{' '}
								<Link to=" https://www.youtube.com/">
									<i class="ri-youtube-line"></i>
								</Link>{' '}
							</span>

							<span>
								{' '}
								<Link to=" https://www.linkedin.com/">
									<i class="ri-linkedin-line"></i>
								</Link>{' '}
							</span>
						</div>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;
