import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import '..//../styles/popup.css'

const Popup = () => {
	return (
		<>
			<div className='thanks'>
				<Container>
					<Row>
						<Col lg="12" >
							<div className='thanks__mess'>
								<h1>Thanks for Shoping</h1>
								<p>This is a Dummy</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}

export default Popup